
.column-container {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    
}
.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
}

img.right-pointer {
    width: 24px;
    height: 24px;
    justify-self: end;
}

img.left-pointer {
    width: 24px;
    height: 24px;
    justify-self: start;
}

.footer-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 1%;
}

.upload-button {
    color: white;
    background-color: #5016EF;
    border-color: #7e5ff2;;
    padding: 12px;
    border-radius: 10px;
    font-size: large;
}

