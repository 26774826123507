html, body {
  font-family: Generation Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  min-width: max-content;
  /* width: 80vw; */
  height: 100%;
  margin: auto;
}

@media all and (device-width: 820px) and (device-height: 1180px) and (orientation:portrait){
  html, body {
    zoom: 0.9;
  }
}

* {
  font-family: Generation Sans, sans-serif;
}

code {
  font-family: Generation Sans;
}
