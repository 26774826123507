.expanded-background {
    background-color: rgba(0,0,0,0.7);
    color: white;
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;   
}
.expanded-background > * {
    position: relative;
    top: 30%;
    z-index: 99;
}

.page {
    font-size: large;
    background: black;
    color: white;
}

.grid-container {
    font-size: smaller;
    position: relative;
}

.regions-grid {
    display: grid;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, .5) .1em, transparent .1em);
    background-size: 11.12% 100%;
    background-position: 6.1%;
    grid-template-columns: 1fr repeat(8, 2fr) 1fr;
    grid-template-rows: repeat(6, 1fr);
    row-gap: 10px;
    padding-top: 15px;
    padding-bottom: 10px;
    position: relative;
    
}

.numbers-grid {
    display: grid;
    width: 100%;
    height: 98%;
    grid-template-columns: 1fr repeat(8, 2fr) 1fr;
    grid-template-rows: repeat(6, 1fr);
    padding-top: 15px;
    padding-bottom: 10px;
    position: absolute;

}

.number-dark{
    opacity: 0.5;
}

.number-light{
    background-image: linear-gradient(90deg, transparent .1em, black .1em);
}
