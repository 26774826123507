.box {
    color: #fff;
    font-size: smaller;
    padding: 7px 8px;
    border-radius: 5px;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    column-gap: 10px;
    height: 120px;
    background-color: #5016ef;
    border: 1px solid #7e5ff2;
    min-width: fit-content;
}

.expanded-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 15px;
    
}
.expanded-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.knob-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}

.knob {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
}

.free {
    background-color: #f7b000;
    border: 1px solid #ffdf80;
}

.break {
    background-color: #7c7c91;
    border: 1px solid #d3d3da;
}

.ambience {
    background-color: #00a693;
    border: 1px solid #04d0b8;
}

.rhythm {
    background-color: #e51a4c;
    border: 1px solid #ff2b60;
}

.melody {
    background-color: rgb(119, 60, 28);
    border: 1px solid rgb(164, 99, 64);
}

.chords {
    background-color: #ef16d9;
    border: 1px solid #ff9af5;
}

img.right-arrow {
    width: 24px;
    height: 24px;
    padding: 1px;
    align-self: center;
    margin-left: auto;
    margin-left: 5px;
}

img.left-arrow {
    width: 24px;
    height: 24px;
    padding: 1px;
    align-self: center;
    margin-left: 5px;
}

img.icon-on {
    width: 24px;
    height: 24px;
    padding: 1px;
    border-radius: 5px;
    margin-left: 5px;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(315deg) brightness(104%) contrast(101%);
    border: 1px solid black;
    background: rgb(255, 255, 255);
    opacity: 0.9;
}

img.icon-off {
    opacity: 50%;
    width: 24px;
    height: 24px;
    padding: 1px;
    border-radius: 5px;
    border: 1px solid transparent;
    border: 1px solid #fff;
    margin-left: 5px;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(315deg) brightness(104%) contrast(101%);
    background: rgb(255, 255, 255);
    opacity: 0.25;
}

img.icon-on-off {
    width: 24px;
    height: 24px;
    padding: 1px;
    border-radius: 5px;
    border: 1px dashed #fff;
    margin-left: 5px;
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(315deg) brightness(104%) contrast(101%);
    background: rgb(255, 255, 255);
    opacity: 0.25;
}

.filter-icon-on {
    width: 24px;
    height: 24px;
    padding: 1px;
    border-radius: 5px;
    margin-left: 5px;
    border: 1px solid #fff;
    opacity: 0.9;
    background: rgb(0, 0, 0);
}

.filter-icon-off {
    width: 24px;
    height: 24px;
    padding: 1px;
    border-radius: 5px;
    border: 1px solid transparent;
    border: 1px solid #fff;
    margin-left: 5px;
    /* filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(315deg) brightness(14%) contrast(101%); */
    background: rgb(0, 0, 0);
    opacity: 0.25;
}

.filter-icon-on-off {
    width: 24px;
    height: 24px;
    padding: 1px;
    border-radius: 5px;
    border: 1px dashed #fff;
    margin-left: 5px;
    opacity: 0.9;
}

img.icons-background {
    position: absolute;
    z-index: -1;
    opacity: 25%;
    height: 72px;
    width: 148px;
}


.icon-container {
    margin-top: auto;
    margin-bottom: auto;
    height: 72px;
    /* padding: 0 2px;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-columns: auto-fit;
    gap: 5px 6px;
    
    height: 72px;
    width: 148px;
    position: relative;
    align-self: center;
    z-index: 0; */
}

.bar {
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 4px;
    border-radius: 5px;
}
